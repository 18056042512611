<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section class="row justify-between">
        <div class="row q-gutter-sm">
          <q-select
            :options="listTingkat"
            v-model="selTingkat"
            outlined
            label="Tingkat"
            style="min-width: 130px;"
            @input="getKelasList()"
          ></q-select>
          <q-btn
            icon="add"
            color="primary"
            label="Tambah Mapel"
            v-can="[]"
            @click="showDialogEdit(-1)"
          ></q-btn>
        </div>
        <q-input outlined label="Cari Mapel" v-model="searchTerm">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-markup-table
        flat
        bordered
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr>
            <th>No</th>
            <th>Nama Mapel</th>
            <th v-if="jenjang == 'MA'">Peminatan</th>
            <th>Kelompok</th>
            <th>KKM</th>
            <th style="width:20px;" v-can="['KURIKULUM']"></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-if="mapel.length == 0">
            <td class="text-center" colspan="5">
              Belum Ada Mata Pelajaran di Tingkat Terpilih
            </td>
          </tr>
          <tr v-for="(val, i) in filMapel" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ val.nama }}</td>
            <td v-if="jenjang == 'MA'">{{ val.peminatan }}</td>
            <td>{{ val.kelompok }}</td>
            <td>{{ val.kkm }}</td>
            <td class="q-gutter-sm" v-can="['KURIKULUM']">
              <q-btn
                flat
                dense
                icon="edit"
                color="secondary"
                @click="showDialogEdit(val.id)"
              ></q-btn>
              <q-btn
                flat
                dense
                @click="
                  (toDel = { id: val.id, nama: val.nama }), (confirm = true)
                "
                color="red"
                icon="delete_forever"
              />
            </td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card>
    <q-dialog v-model="confirm" persistent>
      <q-card class="bg-negative text-white">
        <q-card-section class="row items-center">
          <strong class="q-ml-sm"
            >Apa anda yakin ingin menghapus mata pelajaran "{{ toDel.nama }}"
            ?</strong
          >
          <strong class="q-ml-sm"
            >Data yang Anda Hapus akan berpengaruh terhadap nilai siswa yang
            sudah di inputkan</strong
          >
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Tidak" color="white" v-close-popup />
          <q-btn
            flat
            label="Ya"
            color="white"
            @click="deleteData(toDel.id)"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import DialogPengaturanMapel from "@/components/DialogPengaturanMapel";
export default {
  data() {
    return {
      toDel: {
        id: 0,
        name: "",
      },
      jenjang: localStorage.getItem("jenjang"),
      confirm: false,
      searchTerm: "",
      listTingkat: ["7", "8", "9"],
      selTingkat: "7",
      mapel: [],
    };
  },
  computed: {
    filMapel() {
      if (this.searchTerm == "") return this.mapel;
      else {
        return this.mapel.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }
    },
  },
  async mounted() {
    await this.getTingkat();
    await this.getKelasList();
  },
  methods: {
    async getTingkat() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get("/pengaturan/mapel/gettingkat/" + localStorage.getItem("jenjang"))
        .then((resp) => {
          this.listTingkat = resp.data;
          this.selTingkat = this.listTingkat[resp.data.length - 1];
        });
      this.$q.loading.hide();
    },
    deleteData(id) {
      this.$http.put("/pengaturan/mapel/delmapel/" + id).then((resp) => {
        this.getKelasList();
      });
    },
    async getKelasList() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(
          "/pengaturan/mapel/getlist/" +
            this.selTingkat.value +
            "/" +
            localStorage.getItem("jenjang")
        )
        .then((resp) => {
          this.mapel = resp.data;
        });
      this.$q.loading.hide();
    },
    showDialogEdit(id) {
      this.$q
        .dialog({
          component: DialogPengaturanMapel,
          parent: this,
          idMapel: id,
          pTingkat: this.selTingkat.value,
        })
        .onOk((resp) => {
          this.selTingkat = {
            label: resp.tingkat,
            value: resp.tingkat,
          };
          this.getKelasList();
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
