<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="column q-col-gutter-sm">
        <q-select
          :options="listTingkat"
          :disable="idMapel > 0"
          v-model="bundleMapel.tingkat"
          outlined
          label="Tingkat"
        ></q-select>
        <q-select
          :options="listKelompok"
          v-model="bundleMapel.kelompok"
          outlined
          label="Kelompok"
        ></q-select>
        <q-select
          v-if="jenjang == 'MA'"
          :options="listMinat"
          v-model="bundleMapel.peminatan"
          outlined
          label="Peminatan"
        ></q-select>
        <q-input
          outlined
          v-model="bundleMapel.nama"
          label="Nama Mapel"
        ></q-input>
        <q-input
          outlined
          type="number"
          v-model="bundleMapel.kkm"
          label="KKM"
          @input="getBatas"
        ></q-input>
        <p class="q-mb-none">Batas Nilai:</p>
        <div class="row justify-between">
          <q-input
            outlined
            type="number"
            v-model="bundleMapel.batas_a"
            label="A"
            dense
            style="width:20%"
          ></q-input>
          <q-input
            outlined
            type="number"
            v-model="bundleMapel.batas_b"
            label="B"
            dense
            style="width:20%"
          ></q-input>
          <q-input
            outlined
            type="number"
            v-model="bundleMapel.batas_c"
            label="C"
            dense
            style="width:20%"
          ></q-input>
          <q-input
            outlined
            type="number"
            v-model="bundleMapel.batas_d"
            label="D"
            dense
            style="width:20%"
          ></q-input>
          <a class="text-caption text-red" style="width:100%"
            >*batas yang di inputkan adalah batas bawah pada setiap nilai
            kulaitatif</a
          >
        </div>
      </q-card-section>
      <q-card-actions class="row justify-end">
        <q-btn flat color="primary" label="Cancel" @click="onCancelClick" />
        <q-btn
          unelevated
          v-if="idMapel < 0"
          color="primary"
          @click="createMapel()"
          :disable="
            bundleMapel.nama == null ||
              bundleMapel.kkm == null ||
              bundleMapel.kelompok == null
          "
          >Tambah</q-btn
        >
        <q-btn v-else color="primary" @click="edit">Edit</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ["idMapel", "pTingkat"],
  data() {
    return {
      jenjang: localStorage.getItem("jenjang"),
      listTingkat: [],
      listKelompok: [
        "A : Wajib",
        "B : Wajib",
        "C : Peminatan",
        "D : Kekhasan",
        "Lintas Minat"
      ],
      listMinat: ["UMUM", "IPA", "IPS", "AGAMA"],
      bundleMapel: {
        tingkat: "7",
        nama: null,
        kkm: null,
        kelompok: null,
        peminatan: "UMUM",
        batas_a: 0,
        batas_b: 0,
        batas_c: 0,
        batas_d: 0
      }
    };
  },
  async mounted() {
    this.bundleMapel.tingkat = {
      value: this.pTingkat,
      label: this.pTingkat
    };
    this.getTingkat();
    if (this.idMapel > 0) {
      await this.getMapelDetail();
    }
  },
  methods: {
    getBatas() {
      let tempMapel = JSON.parse(JSON.stringify(this.bundleMapel));
      let patok = 100 - parseFloat(tempMapel.kkm);
      let interval = patok / 3;
      let curNilai = parseFloat(tempMapel.kkm);
      tempMapel.batas_d = 0;
      tempMapel.batas_c = curNilai;
      curNilai = curNilai + interval;
      tempMapel.batas_b = Math.floor(curNilai);
      curNilai = curNilai + interval;
      tempMapel.batas_a = Math.floor(curNilai);
      this.bundleMapel = tempMapel;
    },
    async getTingkat() {
      await this.$http
        .get("/pengaturan/mapel/gettingkat/" + localStorage.getItem("jenjang"))
        .then(resp => {
          this.listTingkat = resp.data;
        });
    },
    async edit() {
      if (this.bundleMapel.nama == "") {
        this.$q.notify({
          message: "Nama Mata Pelajaran Tidak Boleh Kosong",
          color: "negative"
        });
        return;
      }
      this.getBatas();
      this.$http
        .put("/pengaturan/mapel/update", this.bundleMapel)
        .then(resp => {
          this.onOKClick();
        });
    },
    async getMapelDetail() {
      await this.$http
        .get("/pengaturan/mapel/getdetail/" + this.idMapel)
        .then(resp => {
          this.bundleMapel = resp.data;
        });
    },
    async createMapel() {
      if (this.bundleMapel.nama == "") {
        this.$q.notify({
          message: "Nama Mata Pelajaran Tidak Boleh Kosong",
          color: "negative"
        });
        return;
      }
      this.bundleMapel.tingkat = this.bundleMapel.tingkat.value;
      this.bundleMapel.jenjang = localStorage.getItem("jenjang");
      this.$http
        .post("/pengaturan/mapel/tambah", this.bundleMapel)
        .then(resp => {
          this.onOKClick();
        });
    },
    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit("hide");
    },

    onOKClick() {
      let data = {
        tingkat: this.bundleMapel.tingkat
      };
      this.$emit("ok", data);
      this.hide();
    },
    onCancelClick() {
      this.hide();
    }
  }
};
</script>
